import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"

import { lightTheme, darkTheme } from "src/styles/Theme"
import { useDarkMode } from "src/hooks"
import GlobalStyle from "src/styles/GlobalStyle"
import Header from "src/components/Header"

const StyledLayoutWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
`

const Layout = ({ children }) => {
  // Enables dark mode if the user's OS has an active dark theme
  const darkModeEnabled = useDarkMode()
  const theme = darkModeEnabled ? darkTheme : darkTheme

  return (
    <StyledLayoutWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header />
        <main id="main-content">{children}</main>
        {/* <Footer /> */}
      </ThemeProvider>
    </StyledLayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout
