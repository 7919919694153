import React, { useState, useEffect, useContext } from "react"
import Helmet from "react-helmet"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { Link } from "gatsby"

import Context from "src/context"
import { detectMobileAndTablet, isSSR } from "src/utils/"
import ContentWrapper from "src/styles/ContentWrapper"
import StyledBurger from "src/components/Header/StyledBurger"
import Sidebar from "src/components/sidebar"
import Navbar from "src/components/Navbar"

const StyledHeader = motion.custom(styled.header`
  width: 100%;
  height: ${({ theme }) => theme.headerHeight};
  background: ${({ theme }) => theme.colors.background};
`)

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;
    max-width: 62.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em 3em;
  }
`

const Header = () => {
  const { isIntroDone } = useContext(Context).state
  const [open, setOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])

  // Required for animation - start after the splashScreen sequence is done
  const controls = useAnimation()
  useEffect(() => {
    if (isIntroDone) controls.start({ opacity: 1, y: 0, transition: { delay: 0.2 } })
  }, [isIntroDone, controls])

  let navigation

  if (detectMobileAndTablet(windowWidth)) {
    navigation = (
      <>
        <StyledBurger aria-controls="sidebar" open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>
        <Sidebar id="sidebar" open={open} setOpen={setOpen} />
      </>
    )
  } else {
    navigation = <Navbar />
  }

  return (
    <StyledHeader initial={{ opacity: 0, y: -10 }} animate={controls}>
      {/* add blur class to body when sidebar is opened */}
      <Helmet bodyAttributes={{ class: open ? "blur" : "" }} />
      <StyledContentWrapper>{navigation}</StyledContentWrapper>
    </StyledHeader>
  )
}

export default Header
