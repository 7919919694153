const links = {
  // footerLinks: [
  //   {
  //     name: "Privacy",
  //     url: "/privacy",
  //   },
  //   {
  //     name: "Imprint",
  //     url: "/imprint",
  //   },
  // ],
  socialMedia: [
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/imendonca",
    },
    {
      name: "Github",
      url: "https://github.com/ismaelmt",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/ismaelmt",
    },
  ],
  navLinks: {
    menu: [{ name: "Home", url: "/" }],
    button: {
      name: "Blog",
      url: "/#articles",
    },
  },
}

export default links
